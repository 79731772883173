import React, { useState } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { classNames } from "../../../../shared/utility";

export default function ManufactureRecipeItemsTable(props) {
  const {
    selectedRecipe,
    values,
    openAssemblyItemPopUpToAddToProduct,
    openReceivingItemInformation,
    askDeleteProductionIngredient,
    isTraceability,
  } = props;

  const [expandedRows, setExpandedRows] = useState(null);

  const allowExpansion = (rowData) => {
    const receivingItems = rowData.receivingItems ? rowData.receivingItems : [];
    return receivingItems.length > 0;
  };

  const assemblyItemActionTemplate = (rowData) => {
    return (
      <div className="ml-10">
        <button
          onClick={() => {
            openAssemblyItemPopUpToAddToProduct(rowData, true);
          }}
          className={classNames(
            isTraceability ? "hidden" : null,
            "rounded-md bg-yellow-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
          )}
        >
          Edit
        </button>
        <button
          onClick={() => {
            openReceivingItemInformation(rowData);
          }}
          className="ml-2 rounded-md bg-main-purple px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Receiving Info
        </button>
        <button
          onClick={() => askDeleteProductionIngredient(rowData)}
          className={classNames(
            isTraceability ? "hidden" : null,
            "ml-2 rounded-md bg-red-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
          )}
        >
          Delete
        </button>
      </div>
    );
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div className="p-3">
        {/* <h5>Orders for {data.name}</h5> */}
        <DataTable
          value={data.receivingItems}
          tableClassName="min-w-full bg-white divide-y divide-gray-300 manufacture-recipe-items-children-table"
          cellClassName="py-2 bg-gray-100 border-1-bottom-gray px-2"
        >
          <Column field="product_lot" header="Product Lot/Serial"></Column>
          <Column field="supplier_name" header="Supplier"></Column>
          <Column field="product_code" header="Item Plu"></Column>
          <Column field="product_name" header="Item Name"></Column>
          <Column
            field="ingredient_amount_used"
            header="Used Unit"
            style={{ textAlign: "right" }}
          ></Column>
          <Column header="" body={assemblyItemActionTemplate} />
        </DataTable>
      </div>
    );
  };

  const totalPriceBodyTemplate = (rowData) => {
    return (parseFloat(rowData.recipe_per_kg) * values.new_weight).toFixed(2);
  };

  return (
    <div className="flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <h3 className="text-lg mb-2">Recipe ingredients (Guide)</h3>
          <div className="overflow-hidden card-custom bg-white">
            <DataTable
              value={selectedRecipe.recipeItems}
              expandedRows={expandedRows}
              onRowToggle={(e) => {
                setExpandedRows(e.data);
              }}
              rowExpansionTemplate={rowExpansionTemplate}
              dataKey="id"
              tableClassName="min-w-full bg-white divide-y divide-gray-300 manufacture-recipe-items-table"
              cellClassName="py-2 bg-gray-100 border-1-bottom-gray"
            >
              <Column field="bom_plu" header="BOM Plu" />
              <Column field="bom_name" header="BOM name" />
              <Column
                header="Required amount"
                body={totalPriceBodyTemplate}
                style={{ textAlign: "right" }}
              />
              <Column
                field="amount_in_use"
                header="Amount in use"
                style={{ textAlign: "right" }}
              />
              {isTraceability ? null : (
                <Column
                  field="total_cost"
                  header="Total ($)"
                  style={{ textAlign: "right" }}
                />
              )}

              {/* <Column field="price" header="Price" body={priceBodyTemplate} /> */}
              <Column expander={allowExpansion} style={{ width: "5rem" }} />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
}
